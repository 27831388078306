$primaryColor: #1a59a9;
$secondaryColor: #ffcd06;
$color1:  #c3440b;
$greybg: #f1f1f1;
$color2: #666666;
$color3: #999999;
$color4: #cccccc;
$color5: #fff5cd;
$color6: #e3a000;
$bodyTextColor: #000;
$placeholderColor: #000;
$white: #fff;
$black: #000;
$red:#b40015;
$darkGray: #5a5b5d;
$lightGray1: #e3e3e3;
$lightGray2: #cccccc;
$lightGray3: #efefef;
$bodyFontSize: 14px;
$bodyLineHeight: 1.2;
$bodyFontFamily: 'Fira Sans Condensed', sans-serif;
$headingFont: 'Fira Sans Condensed', sans-serif;
$heroFont: 'Fira Sans Condensed', sans-serif;

$h1Size: 30px;
$h2Size: 19px;
$h3Size: 16px;
$h4Size: 16px;


@mixin resetUl {
    list-style: none;
    margin: 0;
    padding: 0;
}

$baseFontSize: $bodyFontSize;
@function r($pixels, $context: $baseFontSize) {
    @if (unitless($pixels)) {
        $pixels: $pixels * 1px;
    }
    @if (unitless($context)) {
        $context: $context * 1px;
    }
    @return $pixels / $context * 1rem;
}